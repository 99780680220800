<template>
    <dv-full-screen-container class="echat_da_pin_container" allowfullscreen="true">
        <dv-loading v-if="showLoaing" style="color:black">数据加载中...</dv-loading>
        <template v-if="showLoaing == false">
            <!-- 头部 -->
            <Top :echartData="echartData" />
            <!-- 四个小块 -->
            <FourCard :echartData="echartData" />
            <!-- 第一个  三块 -->
            <FirstThreeCard :echartData="echartData" />
        </template>
    </dv-full-screen-container>
</template>
<script>
// 头部
import Top from './echatComponents/top.vue'
// 四个小块
import FourCard from './echatComponents/fourCard.vue'
// 第一个 三块
import FirstThreeCard from './echatComponents/first_three.vue'
export default {
    components: {
        Top,
        FourCard,
        FirstThreeCard
    },
    data() {
        return {
            showLoaing: true,
            echartData: null
        }
    }, created() {
        this.showLoaing = true
        this.getEchartData()
    }, methods: {
        getEchartData() {
            let _this = this
            _this.$axios.get('/echarts/getEchartsData').then(res => {
                _this.echartData = res.data
                _this.showLoaing = false
            })
            setInterval(function () {
                _this.$axios.get('/echarts/getEchartsData').then(res => {
                    _this.echartData = res.data
                    _this.showLoaing = false
                })
            }, 10000)
        }
    }
}
</script>
<style scoped>
.echat_da_pin_container {
    background-color: #F3F6F6;
}
</style>
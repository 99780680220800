import { render, staticRenderFns } from "./echart5.vue?vue&type=template&id=5fc87772&scoped=true"
import script from "./echart5.vue?vue&type=script&lang=js"
export * from "./echart5.vue?vue&type=script&lang=js"
import style0 from "./echart5.vue?vue&type=style&index=0&id=5fc87772&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc87772",
  null
  
)

export default component.exports
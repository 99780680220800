<template>
    <div class="four_container">
        <div class="four_container_item four_container_item_first">
            <div class="title">
                <div class="num_class">1</div>
                <div class="title_class">当班产量</div>
            </div>
            <div class="line_class"></div>
            <div class="business_title">
                <div class="business_title_class">
                    实际产量(双)
                </div>
                <div class="business_title_class">
                    理论产量(双)
                </div>
            </div>
            <div class="business_title" style="margin-top:-0.2rem">
                <div class="business_title_class chan_liang_font">
                    {{ echartData.dangBanChanLiang }}
                </div>
                <div class="business_title_class chan_liang_font">
                    {{ echartData.liLunChanLiang }}
                </div>
            </div>
        </div>
        <div class="four_container_item four_container_item_second">
            <div class="title">
                <div class="num_class">2</div>
                <div class="title_class">订单排单</div>
            </div>
            <div class="line_class"></div>
            <div class="business_title">
                <div class="business_title_class">
                    已排订单
                </div>
                <div class="business_title_class">
                    未排订单
                </div>
            </div>
            <div class="business_title" style="margin-top:-0.2rem">
                <div class="business_title_class chan_liang_font">
                    55
                </div>
                <div class="business_title_class chan_liang_font">
                    62
                </div>
            </div>
        </div>
        <div class="four_container_item four_container_item_three">
            <div class="title">
                <div class="num_class">3</div>
                <div class="title_class">车间开机率</div>
            </div>
            <div class="line_class"></div>
            <div class="business_title">
                <div class="business_title_class">
                    开机数
                </div>
                <div class="business_title_class">
                    停机数
                </div>
            </div>
            <div class="business_title" style="margin-top:-0.2rem">
                <div class="business_title_class chan_liang_font">
                    {{ echartData.jiQiCount.kaiJiCount }}
                </div>
                <div class="business_title_class chan_liang_font">
                    {{ echartData.jiQiCount.guanJiCount }}
                </div>
            </div>
        </div>
        <div class="four_container_item four_container_item_four">
            <div class="title">
                <div class="num_class">4</div>
                <div class="title_class">复位统计</div>
            </div>
            <div class="line_class"></div>
            <div class="business_title">
                <div class="business_title_class">
                    复位量(只)
                </div>
                <div class="business_title_class">
                    复位次数
                </div>
            </div>
            <div class="business_title" style="margin-top:-0.2rem">
                <div class="business_title_class chan_liang_font">
                    117.78
                </div>
                <div class="business_title_class chan_liang_font">
                    307
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        echartData: null
    },
    data() {
        return {

        }
    }
}
</script>
<style scoped>
.four_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.four_container_item {
    width: 22%;
    height: 1.5rem;
    border-radius: 0.15rem;
    background-color: antiquewhite;
    margin-top: 0.1rem;
}

.four_container_item_first {
    background: linear-gradient(to right, #2E8588, #3C9998);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.four_container_item_second {
    background: linear-gradient(to right, #DF8251, #F2A075);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.four_container_item_three {
    background: linear-gradient(to right, #03A398, #18B0A6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.four_container_item_four {
    background: linear-gradient(to right, #FFAC42, #FFB75D);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title {
    font-size: 0.26rem;
    color: #fff;
    font-weight: bold;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 0.55rem;
}

.business_title {
    width: 90%;
    height: 0.6rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.business_title_class {
    height: 0.4rem;
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 0.24rem;
    text-align: center;
}


.chan_liang_font {
    font-size: 0.4rem;
    font-weight: bold;
}

.num_class {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #419C99;
    background-color: #fff;
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 10rem;
}

.title_class {
    margin-left: 0.05rem;
}

.line_class {
    background-color: #fff;
    height: 0.01rem;
    width: 40%;
    margin-left: -50%;
}
</style>
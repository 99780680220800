var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"four_container"},[_c('div',{staticClass:"four_container_item four_container_item_first"},[_vm._m(0),_c('div',{staticClass:"line_class"}),_vm._m(1),_c('div',{staticClass:"business_title",staticStyle:{"margin-top":"-0.2rem"}},[_c('div',{staticClass:"business_title_class chan_liang_font"},[_vm._v(" "+_vm._s(_vm.echartData.dangBanChanLiang)+" ")]),_c('div',{staticClass:"business_title_class chan_liang_font"},[_vm._v(" "+_vm._s(_vm.echartData.liLunChanLiang)+" ")])])]),_vm._m(2),_c('div',{staticClass:"four_container_item four_container_item_three"},[_vm._m(3),_c('div',{staticClass:"line_class"}),_vm._m(4),_c('div',{staticClass:"business_title",staticStyle:{"margin-top":"-0.2rem"}},[_c('div',{staticClass:"business_title_class chan_liang_font"},[_vm._v(" "+_vm._s(_vm.echartData.jiQiCount.kaiJiCount)+" ")]),_c('div',{staticClass:"business_title_class chan_liang_font"},[_vm._v(" "+_vm._s(_vm.echartData.jiQiCount.guanJiCount)+" ")])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"num_class"},[_vm._v("1")]),_c('div',{staticClass:"title_class"},[_vm._v("当班产量")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"business_title"},[_c('div',{staticClass:"business_title_class"},[_vm._v(" 实际产量(双) ")]),_c('div',{staticClass:"business_title_class"},[_vm._v(" 理论产量(双) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"four_container_item four_container_item_second"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"num_class"},[_vm._v("2")]),_c('div',{staticClass:"title_class"},[_vm._v("订单排单")])]),_c('div',{staticClass:"line_class"}),_c('div',{staticClass:"business_title"},[_c('div',{staticClass:"business_title_class"},[_vm._v(" 已排订单 ")]),_c('div',{staticClass:"business_title_class"},[_vm._v(" 未排订单 ")])]),_c('div',{staticClass:"business_title",staticStyle:{"margin-top":"-0.2rem"}},[_c('div',{staticClass:"business_title_class chan_liang_font"},[_vm._v(" 55 ")]),_c('div',{staticClass:"business_title_class chan_liang_font"},[_vm._v(" 62 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"num_class"},[_vm._v("3")]),_c('div',{staticClass:"title_class"},[_vm._v("车间开机率")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"business_title"},[_c('div',{staticClass:"business_title_class"},[_vm._v(" 开机数 ")]),_c('div',{staticClass:"business_title_class"},[_vm._v(" 停机数 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"four_container_item four_container_item_four"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"num_class"},[_vm._v("4")]),_c('div',{staticClass:"title_class"},[_vm._v("复位统计")])]),_c('div',{staticClass:"line_class"}),_c('div',{staticClass:"business_title"},[_c('div',{staticClass:"business_title_class"},[_vm._v(" 复位量(只) ")]),_c('div',{staticClass:"business_title_class"},[_vm._v(" 复位次数 ")])]),_c('div',{staticClass:"business_title",staticStyle:{"margin-top":"-0.2rem"}},[_c('div',{staticClass:"business_title_class chan_liang_font"},[_vm._v(" 117.78 ")]),_c('div',{staticClass:"business_title_class chan_liang_font"},[_vm._v(" 307 ")])])])
}]

export { render, staticRenderFns }
<template>
    <div class="top_div_class">
        <div class="top_div_class_item" style="    width: 30%;">
            <div class="top_div_class_item_ban_ci">
                <div style="padding-left:0.4rem">
                    车间：全部&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    区域：全部</div>
            </div>
            <div class="top_div_class_item_ban_ci">
                <div style="padding-left:0.4rem"> 当前班次：全部 &nbsp;&nbsp;&nbsp;&nbsp;
                    {{ echartData.banCi }}&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ echartData.banCiInterval }}&nbsp;&nbsp;&nbsp;&nbsp; 韩聪杰
                </div>
            </div>
        </div>
        <div class="top_div_class_item" style="color:#fff;font-size: .475rem;width:55%">
            耐尔袜业-智能生产管理看板
        </div>
        <div class="top_div_class_item ">
            <div class="top_div_class_item_time">
                <span style="color:#2C9190">占位</span>
            </div>
            <div class="top_div_class_item_time">
                {{ currentTimeStr }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        echartData: null
    },
    data() {
        return {
            currentTimeStr: null,//当前展示时间
        }
    },
    created() {
        this.showTime()
    }, methods: {
        //展示时间
        showTime() {
            var _this = this
            setInterval(function () {
                _this.currentTimeStr = _this.getCurrentDate()
            }, 1000)
        }
    }
}
</script>
<style scoped>
.top_div_class {
    height: 0.7875rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #2C9190;
}

.top_div_class_item {
    width: 20%;
    color: #06ECEC;
    font-size: .18rem;
}

.top_div_class_item_ban_ci {
    display: flex;
    justify-content: space-between;
}

.top_div_class_item_time {
    text-align: right;
    padding-right: .625rem;
}
</style>
<template>
    <div class="echart_container">
        <div class="first_three_container">
            <div class="first_three_container_item">
                <div class="title">
                    工厂效率
                </div>
                <!-- 图表1 -->
                <Echart1 :data1="echartData" />
            </div>
            <div class="first_three_container_item">
                <div class="title">
                    订单进度统计前5名
                </div>
                <!-- 图表2 横向柱状图 -->
                <Echart2 :data2="echartData" />
            </div>
            <div class="first_three_container_item">
                <div class="title">
                    故障次数前5名
                    <div class="title_type">总故障数：
                        <span class="title_type_num">{{ echartData.guZhangNo5.guZhangSumNum }}</span>
                    </div>
                </div>
                <!-- 图表3 -->
                <Echart3 :data3="echartData.guZhangNo5" />
            </div>
        </div>
        <div class="first_three_container">
            <div class="first_three_container_item">
                <div class="title">
                    机器产量末10名
                </div>
                <!-- 图表4 -->
                <Echart4 :data4="echartData" />
            </div>
            <div class="first_three_container_item">
                <div class="title">
                    机器状态分布
                    <div class="title_type">总机器数：
                        <span class="title_type_num">{{ echartData.allJiQiCount }}</span>
                    </div>
                </div>
                <!-- 饼状图 -->
                <Echart5 :data5="echartData.jiQiStatus" />
            </div>
            <div class="first_three_container_item">
                <div class="title">
                    机器故障前10名
                </div>
                <!-- 图表6 -->
                <Echart6 :data6="echartData" />
            </div>
        </div>
    </div>
</template>
<script>
import Echart1 from './components/echart1.vue'
import Echart2 from './components/echart2.vue'
import Echart3 from './components/echart3.vue'
import Echart4 from './components/echart4.vue'
import Echart5 from './components/echart5.vue'
import Echart6 from './components/echart6.vue'
export default {
    props: {
        echartData: {
            guZhangNo5: null,
            jiQiStatus: null
        }
    },
    components: {
        Echart1,
        Echart2,
        Echart3,
        Echart4,
        Echart5,
        Echart6
    }, created() {
        console.log(this.echartData.guZhangNo5, "ppp;;;")
    }
}
</script>
<style scoped>
.echart_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.first_three_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 0.2rem;
}

.first_three_container_item {
    width: 32%;
    border-radius: 0.15rem;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title {
    font-size: 0.23rem;
    width: 97%;
    height: 0.6rem;
    font-weight: bold;
    text-align: left;
    line-height: 0.6rem;
    border-bottom: 0.03rem solid #ECECEC;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.echat_div_class {
    height: 100%;
    width: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title_type {
    font-size: 0.1rem;
}

.title_type_num {
    font-size: 0.3rem;
    color: #03948B;
}
</style>
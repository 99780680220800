<template>
    <div class="echat_div_class">
        <div ref="zhuZhuangEChart" style="width:100%;height:3.8rem;"></div>
    </div>
</template>

<script>
export default {
    props: {
        data6: {}
    },
    data() {
        return {

        }
    }, mounted() {
        this.initCharts()
    }, methods: {
        initCharts() {
            let myChart = this.$echarts.init(this.$refs.zhuZhuangEChart);
            myChart.setOption({
                grid: {
                    // 这个一定要写，不写的话会有默认比列导致图标显示不完整
                    top: "26%",
                    left: "0%",
                    right: "0%",
                    bottom: "0%",
                    containLabel: true // 显示范围包含坐标刻度
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                }, legend: {
                    data: ['故障次数', '机器效率'],
                    top: '13%',
                    top: '10%',
                    itemWidth: this.nowSize(15),  //图形宽度
                    itemHeight: this.nowSize(15),  //图形高度
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.data6.guZhang10Data.x1Data,
                        axisLabel: {
                            formatter: '{value}',
                            fontSize: this.nowSize(15)
                        },
                        axisPointer: {
                            type: 'shadow'
                        }, axisTick: {       //y轴刻度线
                            show: false
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}',
                            fontSize: this.nowSize(15)
                        },
                        splitLine: { show: false },
                        axisTick: {       //y轴刻度线
                            show: false
                        },
                    },
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: this.nowSize(15)
                        },
                        splitLine: { show: false },
                        axisTick: {       //y轴刻度线
                            show: false
                        },
                        tooltip: {
                            formatter: function (value) {
                                return value + ' %';
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '故障次数',
                        type: 'bar',
                        tooltip: {
                            formatter: function (value) {
                                return value;
                            }
                        },
                        data: this.data6.guZhang10Data.x2Data,
                        barWidth: '20%',
                        itemStyle: {
                            normal: {
                                color: '#A08DD7'
                            }
                        }, label: { //柱体上显示数值
                            show: true,//开启显示
                            position: 'top',//在上方显示
                            textStyle: {//数值样式
                                fontSize: this.nowSize(15),
                                color: '#666'
                            },
                            formatter: '{c}',
                        }
                    },
                    {
                        name: '机器效率',
                        type: 'line',
                        yAxisIndex: 1,
                        symbol: 'circle',    //设定为实心点
                        symbolSize: 12,   //设定实心点的大小
                        tooltip: {
                            trigger: 'axis',
                            formatter: function (value) {
                                return value + ' %';
                            }
                        }, itemStyle: {
                            normal: {
                                color: '#FF9F25'
                            }
                        },
                        data: this.data6.guZhang10Data.yData
                    }
                ]
            })
            window.addEventListener("resize", () => {
                setTimeout(() => {
                    myChart.resize();
                }, 100);
            });
        }
    }
}
</script>

<style scoped>
.echat_div_class {
    height: 3.54rem;
    width: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
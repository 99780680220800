<template>
    <div class="echat_div_class">
        <div ref="chart" style="width:90%;height:3.4rem"></div>
    </div>
</template>

<script>
export default {
    props: {
        data2: null
    },
    data() {
        return {

        }
    }, mounted() {
        this.initCharts()
    }, methods: {
        initCharts() {
            let myChart = this.$echarts.init(this.$refs.chart);
            // 绘制图表
            myChart.setOption({
                tooltip: {},
                grid: {
                    // 这个一定要写，不写的话会有默认比列导致图标显示不完整
                    top: "6%",
                    left: "0%",
                    right: "8%",
                    bottom: "4%",
                    containLabel: true // 显示范围包含坐标刻度
                },
                xAxis: {
                    axisLabel: {
                        formatter: '{value} %',
                        textStyle: {
                            show: true,
                            fontFamily: '微软雅黑',
                            color: "#2C3E50",
                            fontSize: this.nowSize(15),
                        },
                    }
                },
                yAxis: {
                    axisLine: {
                        show: false,
                        textStyle: {
                            show: true,
                            fontFamily: '微软雅黑',
                            color: "#2C3E50",
                            fontSize: this.nowSize(15),
                        },
                    },
                    data: this.data2.order5Data.yData,
                    axisLabel: {
                        textStyle: {
                            show: true,
                            fontFamily: '微软雅黑',
                            color: "#2C3E50",
                            fontSize: this.nowSize(15),
                        },
                    }
                },
                series: [{
                    itemStyle: {
                        normal: {
                            //这里是颜色
                            color: function (params) {
                                //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                                var colorList = ['#7CD6CF', '#F89588', '#F8CB7F', '#76DA91', '#63B2EE'];
                                return colorList[params.dataIndex]
                            }
                        }
                    },
                    barWidth: this.nowSize(12),
                    name: '销量',
                    type: 'bar',
                    label: {
                        show: true,
                        position: 'right',
                        formatter: function (params) {
                            return params.value + '%'
                        }
                    },
                    data: this.data2.order5Data.xData
                }]
            });
            window.addEventListener("resize", () => {
                setTimeout(() => {
                    myChart.resize();
                }, 100);
            });
        },
    }
}
</script>

<style scoped>
.echat_div_class {
    width: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<template>
    <div class="echat_div_class">
        <div class="echat_div_class_item_left">
            <dv-charts :option="yiBiaoPanOption" />
        </div>
        <div class="echat_div_class_item_right">
            <div class="echat_div_class_item_right_item" style="background-color:#F3F6F6">
                <div style="width:100%;font-size:0.3rem;">
                    实际产量
                </div>
                <div style="width:100%;color:#03948B;font-size:0.4rem;font-weight:bold">
                    {{ data1.dangBanChanLiang }}
                </div>
            </div>
            <div class="echat_div_class_item_right_item" style="background-color:#FBF6F0">
                <div style="width:100%;font-size:0.3rem;">
                    废片数量
                </div>
                <div style="width:100%;color:#FF9F25;font-size:0.4rem;font-weight:bold">
                    0
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data1: null
    },
    data() {
        return {
            yiBiaoPanOption: {
                series: [
                    {
                        type: 'gauge',
                        startAngle: -Math.PI * 1.3,
                        endAngle: Math.PI * 0.35,
                        arcLineWidth: this.nowSize(20),
                        data: [
                            {
                                name: 'itemA', value: this.data1.gongChangXiaoLv, gradient: ['#03c2fd', '#1ed3e5', '#2fded6']
                            }
                        ],
                        axisLabel: {
                            show: false,
                        },
                        axisTick: {
                            show: false
                        },
                        pointer: {
                            show: false
                        },
                        dataItemStyle: {
                            lineCap: 'round'
                        },
                        details: {
                            show: true,
                            formatter: '{value}%',
                            style: {
                                fill: '#1ed3e5',
                                fontSize: this.fontSize(0.3)
                            }
                        }
                    }
                ]
            }

        }
    }, methods: {
        fontSize(res) {
            let docEl = document.documentElement,
                clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (!clientWidth) return;
            let fontSize = 100 * (clientWidth / 1920);
            return res * fontSize;
        }
    }
}
</script>
<style scoped>
.echat_div_class {
    height: 3.54rem;
    width: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.echat_div_class_item_left {
    height: 3.44rem;
    width: 70%;
}

.echat_div_class_item_right {
    height: 3.44rem;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.echat_div_class_item_right_item {
    width: 100%;
    background-color: aqua;
    margin-top: 0.1rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
</style>
<template>
    <div class="echat_div_class">
        <div ref="huanXingBinZhuangChart" style="width:95%;height:3.8rem;"></div>
    </div>
</template>

<script>
export default {
    props: {
        data5: null
    },
    data() {
        return {

        }
    }, mounted() {
        this.initCharts()
    }, methods: {
        initCharts() {
            let _this = this
            console.log(_this.data3, "ppppppppppppppp")
            let myChart = this.$echarts.init(this.$refs.huanXingBinZhuangChart);
            // 绘制图表
            myChart.setOption({
                grid: {
                    // 这个一定要写，不写的话会有默认比列导致图标显示不完整
                    top: "6%",
                    left: "30%",
                    right: "10%",
                    bottom: "4%",
                    containLabel: true // 显示范围包含坐标刻度
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)',
                    textStyle: {
                        fontWeight: 'normal',
                        color: "#fff",
                        fontSize: this.nowSize(15)
                    },
                },
                color: ['#76DA91', '#F8CB7F', '#63B2EE', '#5A5476', '#7CD6CF', '#F89588'],
                legend: {
                    orient: 'vertical',
                    right: '0%',
                    itemWidth: this.nowSize(15),  //图形宽度
                    itemHeight: this.nowSize(15),  //图形高度
                    top: '13%',
                    data: _this.data5.keyNames,
                    textStyle: {
                        fontWeight: 'normal',
                        color: "#2C3E50",
                        fontSize: this.nowSize(15)
                    },
                },
                series: [
                    {
                        name: '访问来源',
                        type: 'pie',
                        left: '-48%',
                        radius: ['50%', '35%'],
                        itemStyle: {
                            borderRadius: this.nowSize(10),
                            borderColor: '#fff',
                            borderWidth: this.nowSize(10),
                            normal: {
                                label: {
                                    show: true,
                                    formatter: '{b}\n{c}',
                                    textStyle: {
                                        fontWeight: 'normal',
                                        color: "#2C3E50",
                                        fontSize: this.nowSize(15),
                                        zIndex: 999999,
                                    },
                                }, labelLine: {
                                    show: true,//数据标签引导线
                                    length: this.nowSize(25),
                                    lineStyle: {
                                        width: 1,
                                        type: 'solid'
                                    }
                                }
                            }
                        },
                        data: _this.data5.nameValueVoList
                    }
                ]
            });
            window.addEventListener("resize", () => {
                setTimeout(() => {
                    myChart.resize();
                }, 100);
            });
        }
    }
}
</script>

<style scoped>
.echat_div_class {
    width: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>